.were-hiring {
    display: flex;
}

.were-hiring {
    justify-content: center;
    margin-top: 10px;
}

.hiring {
    text-align: center;
    border: 1px solid black;
    border-radius: 5px;
    width: 50%;
}


a {
    color: black;
    text-decoration: none;
}

.contact-social-row {
    margin-top: 25px;
    justify-content: center;
}