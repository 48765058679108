.footer-social-row {
    justify-content: center;
    background-color: white;
    padding: 10px 0px;
}

.footer {
    margin-top: 20px;
}

.footer-address-row {
    color: #2F2E29;
    display: flex;
    justify-content: center;
    background-color: white;
}

.social-icon {
    font-size: 35px;
    display: flex;
    justify-content: center;
    padding: 0;
    color: #2F2E29;
}

.footer-content {
    display: flex;
    color: black;
    justify-content: center;
    text-align: center;
  }