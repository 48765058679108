.products-1, .products-2, .products-3 {
    min-height: 500px;
    position: relative;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.products-1 {
  background-image: url("../../assets/RandCo-Assets/RCOHero.jpg");
  min-height: 70vh;
}

.products-2 {
  background-image: url("../../assets/RandCo-Assets/RCO1.jpg");
  min-height: 70vh;
}

.products-3 {
  background-image: url("../../assets/RandCo-Assets/RCOSoul.jpg");
  min-height: 70vh;
}

.product-content {
  height: 300px;
  text-align: center;
  padding: 50px
}

.caption {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  text-align: center;
  color: #000;
}

/* Turn off parallax scrolling for tablets and phones */
@media only screen and (max-device-width: 1024px) {
  .products-1, .products-2, .products-3 {
    background-attachment: scroll;
  }
}