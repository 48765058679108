  .navbar-toggler {
    background-color: rgb(255, 255, 255);
    padding: 2px 3px 2px 3px;
  }

  .navbar-toggler:hover {
    box-shadow: 0 0 0 0.75px;
    border: 1px solid rgb(252, 245, 229);
    border: 0.75px solid #2f311c;
    transition: 0.3s;
  }

  .nav-link {
    color: black;
  }
  
  .nav-link:hover {
    color: #2f311c;
    transition: 0.3s;
  }

  /* .navbar-toggler:focus {
    color: #2f311c;
    transition: 0.3s;

  } */

  .custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(0, 0, 0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
  
  /* .custom-toggler.navbar-toggler {
    border-color: rgb(0, 0, 0);
  }  */