.home-1, .home-2, .home-3 {
  position: relative;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 55%;
  min-width: fit-content;
  border: 1px solid #2F2E29;
}

.logo-img {
  max-width: 300px;
  max-height: 300px;
}

.logo-row {
  justify-content: center;
  padding-bottom: 20px;
}

.home-1 {
  background-image: url("../../assets/Salon-Assests/Salon1.jpeg");
  min-height: 40vh;

}

.home-2 {
  background-image: url("../../assets/Salon-Assests/Salon2.png");
  min-height: 40vh;
}

.home-copy {
  margin-top: 7px;
}

.buttons {
  display: flex;
  background-color: #A49D93;
  justify-content: center;
  padding: 7px;
  border: none;
  padding-bottom: 16px;
}

.home-content {
  min-height: 20vh;
  color: #F5F0DA;
  background-color: #A49D93;
  padding: 10px;
  text-align: justify;
}

.caption {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  text-align: center;
  color: #000;
}

.caption span.border {
  background-color: #111;
  color: #fff;
  padding: 18px;
  font-size: 25px;
  letter-spacing: 10px;
}

h3 {
    letter-spacing: 5px;
    text-transform: uppercase;
    color: #111;
  }

.cta-button {
  color: black;
  background-color: #A49D93;
  border: 2px solid black;
  text-align: center;
  font-size: 35px;
  
}

.cta-button:hover {
  background-color: #A49D93;
}

  /* @media only screen and (max-device-width: 1366px) {
    .home-1, .home-2, .home-3 {
      background-attachment: scroll;
    }
  } */