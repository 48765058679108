.SOULmates-social-icon {
    font-size: 25px;
    color: rgb(0, 0, 0);
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
}

.card-subtitle {
    margin: 0px;
}

.page-title {
    justify-content: center;
}

.stylist-bio {
    text-align: start;
    white-space: pre-line;
    margin-top: 5px;
    color: #777;
    background-color:white;
}

.book-button {
    margin-top: 5px;
    background-color: transparent; 
    border: 1px solid #000307;
    color: #000307;
    height: 40px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 38px;
    padding: 0 28px;
    border-radius: 0;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    display: inline-block; 
}

.mate-card {
    max-width: 80vw;
}

.mate-row {
    justify-content: center;
}

h3 {
    letter-spacing: 5px;
    text-transform: uppercase;
    color: #111;
    text-align: start;
  }

  h6 {
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #111;
    text-align: start;
  }