@import url('https://fonts.google.com/specimen/M+PLUS+Rounded+1cs');

html, body {
  max-width: 100vw !important;
  overflow-x: hidden !important;
}

body {
  margin: 0;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  height: 100vh;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: #A49D93;
}
